import React, { createContext, useContext, useState, useEffect } from 'react';
import { decodeJWT, isTokenValid } from '../utils/jwtUtils';
import { setCookie, getCookie, eraseCookie } from '../utils/cookieUtils';
import config from '../config';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState('');
  const [userName, setUserName] = useState('');
  const [loadingAuth, setLoadingAuth] = useState(true);

  useEffect(() => {
    const initializeAuth = async () => {
      const token = getCookie('authToken');
      if (token && isTokenValid(token)) {
        const decoded = decodeJWT(token);
        setIsAuthenticated(true);
        setUserId(decoded.id);
        await fetchUserName(decoded.id);
      } else {
        setIsAuthenticated(false);
        setUserId('');
        setUserName('');
      }
      setLoadingAuth(false);
    };
    initializeAuth();
  }, []);

  const fetchUserName = async (id) => {
    try {
      const response = await axios.get(`${config.apiUrl}/api/users/${id}`);
      setUserName(response.data.name);
    } catch (error) {
      console.error('Failed to fetch user name:', error);
    }
  };

  const login = (token) => {
    const decoded = decodeJWT(token);
    setCookie('authToken', token, 1); // Cookie expira em 1 dia
    setIsAuthenticated(true);
    setUserId(decoded.id);
    fetchUserName(decoded.id);
  };

  const logout = () => {
    eraseCookie('authToken');
    setIsAuthenticated(false);
    setUserId('');
    setUserName('');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userId, userName, login, logout, loadingAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
