import React from 'react';
import {
  Box,
  Flex,
  Text,
  Heading,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa';
import Navbar from '../components/Navbar';
import './Dash.css';


const PlanPage = () => {
  const planoAtual = {
    nome: 'Expert+',
    creditos: 100000,
    valor: 'R$ 39,00',
    proximaCobranca: '09/09/2023',
    nomeUsuario: 'Thiago Mascarenhas',
    endereco: 'Rua das Flores, 123, Jardim Paulista, São Paulo, SP, Brasil',
    pagamentos: [
      { plano: 'Plano Expert+', data: '09/05/2023', valor: 'R$ 39,00' },
      { plano: 'Plano Expert+', data: '09/04/2023', valor: 'R$ 39,00' },
      { plano: 'Plano Básico', data: '09/03/2023', valor: 'R$ 19,00' },
    ],
  };

  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.700', 'gray.200');

  return (
    <Flex className='bgfundos'>
      <Box width="380px">
        <Navbar />
      </Box>

      <Box p={8} flex="1">
        <Flex justify="space-between" align="center" mb={8}>
          <Heading as="h1" size="xl">
            Meu Plano
          </Heading>
          <Button colorScheme="purple">Alterar Plano</Button>
        </Flex>

        <Box
          bg={bgColor}
          p={6}
          rounded="md"
          shadow="lg"
          mb={8}
          border="1px"
          borderColor={useColorModeValue('gray.200', 'gray.700')}
        >
          <Heading size="lg" mb={4}>
            Plano Atual: {planoAtual.nome}
          </Heading>
          <Text fontSize="xl" color={textColor} mb={2}>
            Valor: {planoAtual.valor} / mês
          </Text>
          <Text fontSize="lg" color={textColor} mb={2}>
            Próxima Cobrança: {planoAtual.proximaCobranca}
          </Text>
          <Text fontSize="lg" color={textColor} mb={2}>
            Nome do Usuário: {planoAtual.nomeUsuario}
          </Text>
          <Text fontSize="lg" color={textColor}>
            Endereço: {planoAtual.endereco}
          </Text>
        </Box>

        <Box bg={bgColor} p={6} rounded="md" shadow="lg">
          <Heading size="lg" mb={4}>
            Histórico de Pagamentos
          </Heading>
          <Table variant="simple" colorScheme="purple">
            <Thead>
              <Tr>
                <Th>Plano</Th>
                <Th>Data do Pagamento</Th>
                <Th>Valor</Th>
              </Tr>
            </Thead>
            <Tbody>
              {planoAtual.pagamentos.map((pagamento, index) => (
                <Tr key={index}>
                  <Td>{pagamento.plano}</Td>
                  <Td>{pagamento.data}</Td>
                  <Td>{pagamento.valor}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>

        <Stack direction="row" spacing={4} mt={8}>
          <Button colorScheme="red" leftIcon={<FaTrashAlt />}>
            Cancelar Assinatura
          </Button>
          <Button colorScheme="purple" leftIcon={<FaRegEdit />}>
            Alterar Plano
          </Button>
        </Stack>
      </Box>
    </Flex>
  );
};

export default PlanPage;
