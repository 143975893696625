import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Image,
  Text,
  useToast,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  IconButton,
  Link,
  Flex,
} from '@chakra-ui/react';
import { FaWhatsapp, FaEye, FaEyeSlash, FaEnvelope, FaLock } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import { useAuth } from '../context/AuthContext'; // Importar o contexto de autenticação
import './Login.css';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${config.apiUrl}/login`, { email, password });
      const { token } = response.data;
      localStorage.setItem('authToken', token); // Armazene o token no localStorage
      login(token); // Chame a função de login para atualizar o contexto de autenticação
      toast({
        title: "Login realizado com sucesso.",
        description: "Você será redirecionado em breve.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Erro ao realizar login.",
        description: error.response ? error.response.data.error : "Erro de rede",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
   

  return (
    <Flex
      height="100vh"
      alignItems="center"
      justifyContent="center"
      backgroundColor="#e5f6d0"
      backgroundImage="url('/img/bgs.png')"
      backgroundSize="cover"
      backgroundPosition="center"
      className="fundonew"
      padding={4}
    >
      <Image
        src="/img/logo.svg"
        alt="WhatsApp Logo"
        boxSize="50px"
        className="logologin"
      />
      <Box className='boxlogin'
        p={8}
        bg="#ecf1f6"
        borderRadius="lg"
        boxShadow="xl"
        maxW="md"
        w="100%"
      >
        <VStack spacing={6}> 
          <Text fontSize="lg" color="#000000" textAlign="center">
            <Text className='logintxt'>Faça login na sua conta</Text>
          </Text>
          <FormControl id="email" isRequired>
            <FormLabel className="label" color="#000000">Email</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none" children={<FaEnvelope color="#6aab6d" />} />
              <Input
                type="email"
                className="input inputs"
                placeholder=""
                focusBorderColor="#38b2ac38"
                bg="gray.100"
                _hover={{ bg: "#ecf1f6" }}
                _focus={{ bg: "#ecf1f6" }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputGroup>
          </FormControl>
          <FormControl id="password" isRequired>
            <FormLabel className="label" color="#000000">Senha</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none" children={<FaLock color="#6aab6d" />} />
              <Input
                type={showPassword ? "text" : "password"}
                className="input inputs"
                placeholder=""
                focusBorderColor="#38b2ac38"
                bg="#ecf1f6"
                _hover={{ bg: "#ecf1f6" }}
                _focus={{ bg: "#ecf1f6" }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement>
                <IconButton
                  icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                  variant="ghost"
                  color="#6aab6d"
                  onClick={() => setShowPassword(!showPassword)}
                  aria-label={showPassword ? "Hide password" : "Show password"}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Button
  leftIcon={
    <Image 
      src="/img/icon.png" 
      alt="Icon" 
      className='icons'
      boxSize="20px" // Adiciona um espaço à direita da imagem
    />
  }
  color="white"
  bg="#6aab6d"
  className='input okss'
  width="270px"
  size="100px"
  onClick={handleLogin}
  isLoading={isLoading}
  mt={4}
>
  ENTRAR AGORA
</Button>
          <Link color="#6aab6d" href="#" alignSelf="flex-end" mt={2}>
            Esqueceu a senha?
          </Link>
        </VStack>
      </Box>
    </Flex>
  );
};

export default LoginPage;
