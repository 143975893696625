import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import axios from 'axios';
import Login from './pages/Login';
import Home from './pages/Home';
import ManageNumbers from './pages/ManageNumbers';
import ManageMessages from './pages/ManageMessages';
import Dashboard from './pages/Dashboard';
import Minhaassinatura from './pages/Minhaassinatura';
import ScanQRCode from './pages/ScanQRCode';
import EditIntegration from './pages/EditIntegration';
import { useAuth } from './context/AuthContext';

import Logout from './components/Logout';
import PrivateRoute from './components/PrivateRoute';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const App = () => {
  const { isAuthenticated, loadingAuth } = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(request => {
      setLoading(true);
      return request;
    }, error => {
      setLoading(false);
      return Promise.reject(error);
    });

    const responseInterceptor = axios.interceptors.response.use(response => {
      setLoading(false);
      return response;
    }, error => {
      setLoading(false);
      return Promise.reject(error);
    });

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  if (loadingAuth) {
    return (
      <div style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 99999
      }}>
        <ClipLoader css={override} size={150} color={"#123abc"} loading={true} />
      </div>
    );
  }

  return (
    <div>
      {loading && (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 99999
        }}>
          <ClipLoader css={override} size={150} color={"#123abc"} loading={loading} />
        </div>
      )}
      <Routes>
        <Route path="/" element={isAuthenticated ? <Navigate to="/numeros" /> : <Login />} />
        <Route element={<PrivateRoute />}>
          <Route path="/home" element={<Home />} />
          <Route path="/numeros" element={<ManageNumbers />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/edit/:id" element={<EditIntegration />} />
          <Route path="/scan/:id" element={<ScanQRCode />} />
          <Route path="/manage-messages" element={<ManageMessages />} />
          <Route path="/minha-assinatura" element={<Minhaassinatura />} />
          
        </Route>
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </div>
  );
};

export default App;
