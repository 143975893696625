import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  Tooltip,
  useToast,
  VStack,
} from "@chakra-ui/react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import {
  FaClock,
  FaCopy,
  FaEdit,
  FaImage,
  FaMicrophone,
  FaPaperPlane,
  FaSmile,
  FaTag,
  FaTrash,
} from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Navbar from "../components/Navbar";
import config from "../config";
import "./EditIntegration.css";

const EditIntegration = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [integration, setIntegration] = useState(null);
  const [integrationName, setIntegrationName] = useState("");
  const [integrationPlatform, setIntegrationPlatform] = useState("");
  const [webhookUrl, setWebhookUrl] = useState("");
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showVariables, setShowVariables] = useState(false);
  const [showEditEmojiPicker, setShowEditEmojiPicker] = useState(false);
  const editEmojiPickerRef = useRef(null);
  const [showEditVariables, setShowEditVariables] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCategoryMessages, setCurrentCategoryMessages] = useState([]);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editingMessageContent, setEditingMessageContent] = useState("");
  const [editingMessage, setEditingMessage] = useState({
    category: "",
    content: "",
    delay: "",
    type: "",
  });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [openDelayModal, setOpenDelayModal] = useState(false);
  const [availableNumbers, setAvailableNumbers] = useState([]);
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const emojiPickerRef = useRef(null);
  const fileInputRef = useRef(null);
  const imageInputRef = useRef(null);
  const videoInputRef = useRef(null);
  const audioInputRef = useRef(null);
  const toast = useToast();
  const messageInputRef = useRef(null); // Para a nova mensagem
  const editMessageInputRef = useRef(null); // Para o campo de edição
  const [currentDelay, setCurrentDelay] = useState({
    timeValue: "",
    timeType: "",
  });

  useEffect(() => {
    // Função para buscar integração e números disponíveis
    fetchIntegration();
    fetchAvailableNumbers();

    // Função para lidar com cliques fora do emoji picker
    const handleClickOutside = (event) => {
      if (
        (emojiPickerRef.current &&
          !emojiPickerRef.current.contains(event.target)) ||
        (editEmojiPickerRef.current &&
          !editEmojiPickerRef.current.contains(event.target))
      ) {
        setShowEmojiPicker(false);
        setShowEditEmojiPicker(false);
      }
    };

    // Adicionar o listener de mousedown para detectar cliques fora do picker
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup para remover o listener quando o componente desmontar ou id mudar
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [id]);

  useEffect(() => {
    if (integration) {
      const initialSelectedNumbers = integration.numbers.map((number) => ({
        value: number,
        label: number,
      }));
      setSelectedNumbers(initialSelectedNumbers);
    }
  }, [integration]);

  const fetchIntegration = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.get(
        `${config.apiUrl}/api/integrations/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIntegration(response.data);
      setIntegrationName(response.data.name);
      setIntegrationPlatform(response.data.platform);
      setWebhookUrl(response.data.webhookUrl);
      setMessages(response.data.messages || []);
    } catch (error) {
      console.error("Failed to fetch integration:", error);
      toast({
        title: "Erro ao buscar integração.",
        description: error.response
          ? error.response.data.error
          : "Erro de rede",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchAvailableNumbers = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.get(`${config.apiUrl}/api/numbers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const activeNumbers = response.data.filter(
        (number) => number.status === "Active"
      );
      setAvailableNumbers(
        activeNumbers.map((number) => ({
          value: number.number,
          label: number.number,
        }))
      );
    } catch (error) {
      console.error("Failed to fetch available numbers:", error);
      toast({
        title: "Erro ao buscar números disponíveis.",
        description: error.response
          ? error.response.data.error
          : "Erro de rede",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchMessages = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.get(
        `${config.apiUrl}/api/integrations/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessages(response.data.messages || []);
      setCurrentDelay({
        timeValue: "",
        timeType: "",
      });
      setCurrentCategoryMessages(
        response.data.messages.filter(
          (msg) => msg.category === selectedCategory
        )
      );
    } catch (error) {
      console.error("Failed to fetch messages:", error);
      toast({
        title: "Erro ao buscar mensagens.",
        description: error.response
          ? error.response.data.error
          : "Erro de rede",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleUpdateIntegration = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const selectedNumberIds = selectedNumbers.map((number) => number.value);
      await axios.put(
        `${config.apiUrl}/api/integrations/${id}`,
        {
          name: integrationName,
          platform: integrationPlatform,
          messages,
          numbers: selectedNumberIds.length > 0 ? selectedNumberIds : [], // Atualizado para permitir nenhum número selecionado
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast({
        title: "Integração atualizada.",
        description: "A integração foi atualizada com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      navigate("/home");
    } catch (error) {
      console.error("Failed to update integration:", error);
      toast({
        title: "Erro ao atualizar integração.",
        description: error.response
          ? error.response.data.error
          : "Erro de rede",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSelectChange = (selectedOptions) => {
    const noneOption = selectedOptions.find(
      (option) => option.value === "none"
    );
    if (noneOption) {
      setSelectedNumbers([]);
    } else {
      setSelectedNumbers(selectedOptions);
    }
  };

  const handleCopyWebhook = () => {
    navigator.clipboard
      .writeText(webhookUrl)
      .then(() => {
        toast({
          title: "Webhook copiado.",
          description:
            "O URL do webhook foi copiado para a área de transferência.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: "Erro ao copiar.",
          description: "Não foi possível copiar o URL do webhook.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleOpenModal = (category) => {
    setSelectedCategory(category);
    setCurrentCategoryMessages(
      messages.filter((msg) => msg.category === category)
    );
    setIsModalOpen(true);
  };

  const handleAddMessage = async () => {
    let newMessageObject = {
      category: selectedCategory,
      content: newMessage,
    };

    if (currentDelay.timeValue !== "" && currentDelay.timeType !== "") {
      newMessageObject = {
        ...newMessageObject,
        delay: `${currentDelay.timeValue}${currentDelay.timeType}`,
      };
    }

    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.post(
        `${config.apiUrl}/api/integrations/${id}/messages`,
        newMessageObject,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await fetchMessages();

      setNewMessage("");
      setEditingMessageId(null);

      toast({
        title: "Mensagem adicionada.",
        description: "A mensagem foi adicionada com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Failed to add message:", error);
      toast({
        title: "Erro ao adicionar mensagem.",
        description: error.response
          ? error.response.data.error
          : "Erro de rede",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleInsertVariable = (variable) => {
    if (isEditModalOpen) {
      const input = editMessageInputRef.current;
      insertAtCursor(input, variable, true);
    } else {
      const input = messageInputRef.current;
      insertAtCursor(input, variable, false);
    }
  };

  const insertAtCursor = (input, variable, isEdit) => {
    // Verifique se o elemento input é válido
    if (!input) {
      console.error("Elemento input não encontrado ou não focado.");
      return;
    }

    const startPos = input.selectionStart;
    const endPos = input.selectionEnd;

    const currentValue = isEdit ? editingMessageContent : newMessage;
    const newValue =
      currentValue.substring(0, startPos) +
      variable +
      currentValue.substring(endPos);

    if (isEdit) {
      setEditingMessageContent(newValue);
    } else {
      setNewMessage(newValue);
    }

    // Define a nova posição do cursor logo após a inserção da variável
    setTimeout(() => {
      input.focus();
      input.setSelectionRange(
        startPos + variable.length,
        startPos + variable.length
      );
    }, 0);
  };

  const handleEditMessage = (messageId) => {
    const messageToEdit = messages.find((msg) => msg._id === messageId);
    if (messageToEdit) {
      setEditingMessage({
        category: messageToEdit.category,
        content: messageToEdit.content,
        delay: messageToEdit.delay ? messageToEdit.delay.slice(0, -1) : "", // Remove o último caractere
        type: messageToEdit.delay ? messageToEdit.delay.slice(-1) : "", // Pega o último caractere
      });
      setEditingMessageContent(messageToEdit.content);
      setEditingMessageId(messageToEdit._id);
      setIsEditModalOpen(true);
    } else {
      console.error("Mensagem não encontrada para edição:", messageId);
    }
  };

  const handleSaveEditMessage = async () => {
    try {
      const token = localStorage.getItem("authToken");

      let updatedMessage = {
        content: editingMessageContent,
      };

      if (editingMessage.delay && editingMessage.delay !== "") {
        updatedMessage = {
          ...updatedMessage,
          delay: `${editingMessage.delay.trim()}${editingMessage.type.trim()}`,
        };
      }

      await axios.put(
        `${config.apiUrl}/api/integrations/${id}/messages/${editingMessageId}`,
        updatedMessage,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await fetchMessages();

      setEditingMessageContent("");
      setEditingMessageId(null);
      setIsEditModalOpen(false);

      toast({
        title: "Mensagem editada.",
        description: "A mensagem foi editada com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Failed to edit message:", error);
      toast({
        title: "Erro ao editar mensagem.",
        description: error.response
          ? error.response.data.error
          : "Erro de rede",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeleteMessage = async (messageId) => {
    if (!messageId) {
      console.error("ID da mensagem para exclusão não definido.");
      return;
    }

    try {
      const token = localStorage.getItem("authToken");
      await axios.delete(
        `${config.apiUrl}/api/integrations/${id}/messages/${messageId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await fetchMessages();

      const deletedMessage = messages.find((msg) => msg._id === messageId);
      if (deletedMessage && deletedMessage.filePath) {
        await axios.delete(`${config.apiUrl}/api/files`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: { filePath: deletedMessage.filePath },
        });
      }

      toast({
        title: "Mensagem removida.",
        description: "A mensagem foi removida com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Failed to delete message:", error);
      toast({
        title: "Erro ao remover mensagem.",
        description: error.response
          ? error.response.data.error
          : "Erro de rede",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeleteTemplate = async (category) => {
    const updatedMessages = messages.filter((msg) => msg.category !== category);
    setMessages(updatedMessages);
    setCurrentCategoryMessages([]);

    try {
      const token = localStorage.getItem("authToken");
      await axios.delete(
        `${config.apiUrl}/api/integrations/${id}/categories/${category}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      updatedMessages
        .filter((msg) => msg.category === category && msg.filePath)
        .forEach(async (msg) => {
          await axios.delete(`${config.apiUrl}/api/files`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: { filePath: msg.filePath },
          });
        });

      toast({
        title: "Template removido.",
        description: "O template foi removido com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Failed to delete template:", error);
      toast({
        title: "Erro ao remover template.",
        description: error.response
          ? error.response.data.error
          : "Erro de rede",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const formatMessage = (message) => {
    // Regex para capturar links de arquivos dentro da tag <a>
    const linkRegex = /<a href="(.*?)".*?>(.*?)<\/a>/;

    // Verificar se a mensagem contém um link
    const match = message.match(linkRegex);

    if (match && match[1]) {
      const url = match[1]; // O link real
      const fileName = match[2]; // O nome do arquivo

      // Regex para identificar tipos de arquivos
      const imageRegex = /\.(webp|jpg|jpeg|png|gif|svg)$/i;
      const audioRegex = /\.(mp3|ogg|opus|wav)$/i;
      const videoRegex = /\.(mp4|3gp|avi|mov)$/i;

      // Se for uma imagem, renderiza a miniatura
      if (imageRegex.test(url)) {
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            <img
              src={url}
              alt={fileName}
              style={{ maxWidth: "100px", maxHeight: "100px" }}
            />
          </a>
        );
      }

      // Se for um áudio, renderiza um player de áudio
      if (audioRegex.test(url)) {
        return (
          <audio controls>
            <source src={url} type={`audio/${url.split(".").pop()}`} />
            Seu navegador não suporta o elemento de áudio.
          </audio>
        );
      }

      // Se for um vídeo, renderiza um player de vídeo
      if (videoRegex.test(url)) {
        return (
          <video controls width="250">
            <source src={url} type={`video/${url.split(".").pop()}`} />
            Seu navegador não suporta o elemento de vídeo.
          </video>
        );
      }

      // Se não for nenhum desses tipos, retorna o link normalmente
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {fileName}
        </a>
      );
    }

    // Caso a mensagem não contenha um link, renderiza como texto normal
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: message
            .replace(/\n/g, "<br />")
            .replace(/\*(.*?)\*/g, "<b>$1</b>")
            .replace(/_(.*?)_/g, "<i>$1</i>")
            .replace(/~(.*?)~/g, "<s>$1</s>"),
        }}
      />
    );
  };

  const handleEmojiClick = (event) => {
    if (isEditModalOpen) {
      setEditingMessageContent(editingMessageContent + event.native);
    } else {
      setNewMessage(newMessage + event.native);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("integrationId", id);
    formData.append("category", selectedCategory);

    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.post(
        `${config.apiUrl}/api/upload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const fileUrl = response.data.url;
      const newMessageObject = {
        _id: uuidv4(), // Gera um ID para garantir que o React renderize corretamente
        category: selectedCategory,
        content: `<a href="${fileUrl}" target="_blank">${file.name}</a>`, // Formato com link
        filePath: response.data.filePath,
      };

      // Atualiza o estado de mensagens e a categoria atual com a nova mensagem
      setMessages((prevMessages) => [...prevMessages, newMessageObject]);
      setCurrentCategoryMessages((prevMessages) => [
        ...prevMessages,
        newMessageObject,
      ]);

      // Limpa o campo de input de arquivos
      event.target.value = null;

      // Renderiza a mensagem formatada imediatamente
      toast({
        title: "Arquivo enviado com sucesso.",
        description: "O arquivo foi carregado e a mensagem foi adicionada.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Erro ao enviar arquivo:", error);
      toast({
        title: "Erro ao enviar arquivo.",
        description: error.response
          ? error.response.data.error
          : "Erro de rede",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSubmitDelay = async (e) => {
    e.preventDefault();

    const timeValue = e.target.elements["time-value"].value;
    const timeType = e.target.elements["time-type"].value;

    setCurrentDelay({
      timeValue,
      timeType,
    });
    setOpenDelayModal(false);
  };

  const categories = [
    {
      key: "[Pix] Aguardando Pagamento",
      label: "Pix Gerado",
      description: "Essa mensagem vai chegar quando um pix for gerado",
    },
    {
      key: "Compra Recusada",
      label: "Pagamento Negado",
      description: "Essa mensagem será enviada quando o pagamento for negado",
    },
    {
      key: "Compra Aprovada",
      label: "Compra Aprovada",
      description: "Essa mensagem será enviada quando a compra for aprovada",
    },
    {
      key: "Reembolso Efetuado",
      label: "Reembolso",
      description:
        "Essa mensagem será enviada quando um reembolso for efetuado",
    },
    {
      key: "Carrinho Abandonado",
      label: "Carrinho Abandonado",
      description:
        "Essa mensagem será enviada quando um cliente abandonar o checkout",
    },
  ];

  const variables = [
    { label: "Primeiro Nome", value: "${contactFirstName}" },
    { label: "Nome Completo", value: "${contactFullName}" },
    { label: "Nome do Produto", value: "${productName}" },
    { label: "Email do comprador", value: "${Customer.email}" },
    { label: "Código do Pix", value: "${pix}" },
    { label: "CPF do comprador", value: "${cpf}" },
    { label: "Código da compra", value: "${identificador}" },
  ];

  return (
    <Box className="geral" ml={{ base: "0", md: "260px" }} p={4}>
      <Navbar />
      <Flex direction="column" alignItems="center">
        <Box
          bg="white"
          borderRadius="md"
          p={6}
          boxShadow="md"
          width={{ base: "100%", md: "50%" }}
          mb={4}
        >
          <Heading as="h2" size="lg" mb={6}>
            Editar Integração
          </Heading>
          <VStack spacing={4}>
            <FormControl id="integration-name" isRequired>
              <FormLabel>Nome da Integração</FormLabel>
              <Input
                value={integrationName}
                onChange={(e) => setIntegrationName(e.target.value)}
              />
            </FormControl>
            <FormControl id="integration-platform" isRequired>
              <FormLabel>Categoria</FormLabel>
              <Select
                value={integrationPlatform}
                onChange={(e) => setIntegrationPlatform(e.target.value)}
              >
                <option value="">Selecione uma integração</option>
                <option value="Kiwify">Kiwify</option>
                <option value="Cakto">Cakto</option>
                <option value="Eduzz">Eduzz</option>
                <option value="Hotmart">Hotmart</option>
              </Select>
            </FormControl>
            <FormControl id="webhook-url" isReadOnly>
              <FormLabel>Webhook URL:</FormLabel>
              <InputGroup>
                <Input value={webhookUrl} isReadOnly />
                <InputRightElement width="4.5rem">
                  <IconButton
                    icon={<FaCopy />}
                    aria-label="Copiar Webhook"
                    onClick={handleCopyWebhook}
                    size="sm"
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Button colorScheme="teal" onClick={handleUpdateIntegration}>
              Salvar Integração
            </Button>
          </VStack>
        </Box>
        <Box flex="3" width="100%" className="tabelabox">
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
            gap={4}
            className="tabelageral"
          >
            {categories
              .filter(
                (category) =>
                  integrationPlatform !== "Kiwify" ||
                  [
                    "Pix Gerado",
                    "Pagamento Negado",
                    "Compra Aprovada",
                    "Reembolso",
                    "Carrinho Abandonado",
                  ].includes(category.label)
              )
              .map((category) => (
                <GridItem key={category.key} className="tabelagrid">
                  <Box
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                    p={4}
                    bg="white"
                    boxShadow="md"
                  >
                    <Heading as="h4" size="md" mb={2}>
                      {integrationPlatform === "Kiwify"
                        ? category.label
                        : category.key}
                    </Heading>
                    <Text fontSize="sm" color="gray.600" mb={4}>
                      {category.description}
                    </Text>
                    {messages.filter((msg) => msg.category === category.key)
                      .length > 0 ? (
                      <>
                        <Button
                          colorScheme="purple"
                          size="sm"
                          onClick={() => handleOpenModal(category.key)}
                        >
                          Configurar template
                        </Button>
                        <Button
                          colorScheme="red"
                          size="sm"
                          ml={2}
                          variant="outline"
                          onClick={() => handleDeleteTemplate(category.key)}
                        >
                          Remover template
                        </Button>
                      </>
                    ) : (
                      <Button
                        colorScheme="teal"
                        size="sm"
                        onClick={() => handleOpenModal(category.key)}
                      >
                        Adicionar template
                      </Button>
                    )}
                  </Box>
                </GridItem>
              ))}
          </Grid>
        </Box>
      </Flex>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent maxH="80vh" overflowY="auto">
          <ModalHeader>{selectedCategory}</ModalHeader>
          <ModalCloseButton />
          <ModalBody className="corpodomodal">
            <VStack spacing={4}>
              {currentCategoryMessages.map((msg) => {
                return (
                  <Box
                    key={msg._id}
                    bg="green.50"
                    p={4}
                    borderRadius="md"
                    w="100%"
                    pos="relative"
                  >
                    <div>
                      {msg.delay && (
                        <Text fontSize="sm" color="gray.600" mb={2}>
                          <strong>Delay:</strong> {msg.delay}
                        </Text>
                      )}
                      {formatMessage(msg.content)}
                    </div>
                    <Flex pos="absolute" top="4px" right="4px">
                      <IconButton
                        icon={<FaEdit />}
                        size="sm"
                        mr={2}
                        onClick={() => handleEditMessage(msg._id)}
                      />
                      <IconButton
                        icon={<FaTrash />}
                        size="sm"
                        onClick={() => handleDeleteMessage(msg._id)}
                      />
                    </Flex>
                  </Box>
                );
              })}
            </VStack>
          </ModalBody>
          <ModalFooter
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FormControl w="100%">
              <Flex
                align="center"
                mb={4}
                w="100%"
                direction={{ base: "column", md: "row" }}
              >
                <Tooltip label="Anexar imagem">
                  <Button as="label" htmlFor="image-upload" mr={2}>
                    <FaImage />
                  </Button>
                </Tooltip>
                <Input
                  id="image-upload"
                  type="file"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={handleFileUpload}
                  ref={imageInputRef}
                />
                <Tooltip label="Anexar áudio">
                  <Button as="label" htmlFor="audio-upload" mr={2}>
                    <FaMicrophone />
                  </Button>
                </Tooltip>
                <Tooltip label="Inserir delay">
                  <Button
                    as="label"
                    htmlFor="delay"
                    mr={2}
                    onClick={() => setOpenDelayModal(true)}
                  >
                    <FaClock />
                  </Button>
                </Tooltip>
                <Input
                  id="audio-upload"
                  type="file"
                  style={{ display: "none" }}
                  accept="audio/*"
                  onChange={handleFileUpload}
                  ref={audioInputRef}
                />

                <Input
                  ref={messageInputRef} // Referência adicionada
                  placeholder="Digite sua mensagem"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  flex="1"
                  as="textarea"
                  resize="none"
                  overflow="hidden"
                  onInput={(e) => {
                    e.target.style.height = "auto";
                    e.target.style.height = e.target.scrollHeight + "px";
                  }}
                  mt={{ base: 2, md: 0 }}
                />

                <Tooltip label="Inserir variável">
                  <IconButton
                    className="botaounico"
                    icon={<FaTag />}
                    onClick={() => setShowVariables(!showVariables)}
                    ml={2}
                    mr={2}
                    mt={{ base: 2, md: 0 }}
                  />
                </Tooltip>
                <Tooltip label="Inserir emoji">
                  <IconButton
                    className="botaounico"
                    icon={<FaSmile />}
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                    mr={2}
                    mt={{ base: 2, md: 0 }}
                  />
                </Tooltip>
                <IconButton
                  className="botaounico"
                  icon={<FaPaperPlane />}
                  aria-label="Enviar mensagem"
                  onClick={handleAddMessage}
                  mt={{ base: 2, md: 0 }}
                />
              </Flex>
              {showVariables && (
                <Flex wrap="wrap">
                  {variables.map((variable) => (
                    <Button
                      key={variable.value}
                      onClick={() => handleInsertVariable(variable.value)}
                      m={1}
                      size="sm"
                    >
                      {variable.label}
                    </Button>
                  ))}
                </Flex>
              )}
              {showEmojiPicker && (
                <Box
                  ref={emojiPickerRef}
                  position="absolute"
                  bottom="60px"
                  right="20px"
                >
                  <Picker
                    data={data}
                    onEmojiSelect={handleEmojiClick}
                    theme="light"
                    locale="pt"
                  />
                </Box>
              )}
            </FormControl>
            {currentDelay.timeValue !== "" && currentDelay.timeType !== "" && (
              <Text fontSize={"small"}>
                <strong>O delay configurado para a mensagem atual é de:</strong>{" "}
                {currentDelay.timeValue}{" "}
                {currentDelay.timeType === "s"
                  ? "segundos."
                  : currentDelay.timeType === "m"
                  ? "minutos."
                  : currentDelay.timeType === "h" && "horas."}
              </Text>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        className="editforms"
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        size="xl"
      >
        {" "}
        {/* Mudando o tamanho do modal */}
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar mensagem</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Mensagem</FormLabel>
              <Textarea
                ref={editMessageInputRef}
                value={editingMessageContent}
                onChange={(e) => setEditingMessageContent(e.target.value)}
                placeholder="Edite sua mensagem"
                resize="none"
                overflowY="auto"
                height="auto"
                minHeight="150px"
                maxHeight="300px"
                width="100%"
                onInput={(e) => {
                  e.target.style.height = "auto";
                  e.target.style.height = `${Math.min(
                    e.target.scrollHeight,
                    300
                  )}px`;
                }}
              />
              {editingMessage.delay !== "" && (
                <div
                  style={{
                    marginTop: "6px",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: "6px",
                  }}
                >
                  <strong>Delay</strong>
                  <Input
                    id="delay"
                    placeholder="Informe o delay aqui, ex: 4h"
                    defaultValue={editingMessage.delay}
                    type="number"
                    onChange={(e) => {
                      setEditingMessage({
                        ...editingMessage,
                        delay: e.target.value,
                      });
                    }}
                  />
                  <Select
                    placeholder="Selecione a unidade de tempo"
                    id="time-type"
                    defaultValue={editingMessage.type}
                    onChange={(e) => {
                      setEditingMessage({
                        ...editingMessage,
                        type: e.target.value,
                      });
                    }}
                  >
                    <option value="s">Segundo</option>
                    <option value="m">Minutos</option>
                    <option value="h">Horas</option>
                  </Select>
                  <Text fontSize={14} textColor={"GrayText"}>
                    O delay configurado para essa mensagem é de{" "}
                    <strong>
                      {editingMessage.delay}
                      {editingMessage.type}
                    </strong>
                    , caso queira alterar, basta{" "}
                    <strong>alterar o valor e salvar.</strong>
                  </Text>
                </div>
              )}
              <Tooltip label="Inserir variável">
                <IconButton
                  icon={<FaTag />}
                  onClick={() => setShowEditVariables(!showEditVariables)}
                  mr={2}
                  mt={2}
                />
              </Tooltip>
              {showEditVariables && (
                <Flex wrap="wrap" mt={2}>
                  {variables.map((variable) => (
                    <Button
                      key={variable.value}
                      onClick={() => handleInsertVariable(variable.value)}
                      m={1}
                      size="sm"
                    >
                      {variable.label}
                    </Button>
                  ))}
                </Flex>
              )}
              <Tooltip label="Inserir emoji">
                <IconButton
                  icon={<FaSmile />}
                  onClick={() => setShowEditEmojiPicker(!showEditEmojiPicker)}
                  mr={2}
                  mt={2}
                />
              </Tooltip>
              {showEditEmojiPicker && (
                <Box
                  ref={editEmojiPickerRef}
                  position="relative"
                  zIndex={1000}
                  mt={2}
                >
                  <Picker
                    data={data}
                    onEmojiSelect={handleEmojiClick}
                    theme="light"
                    locale="pt"
                  />
                </Box>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              className="noatuar"
              colorScheme="teal"
              onClick={handleSaveEditMessage}
            >
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={openDelayModal}
        onClose={() => setOpenDelayModal(false)}
        size={"sm"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Inserir delay</ModalHeader>
          <ModalBody
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "22px",
            }}
          >
            <form onSubmit={handleSubmitDelay}>
              <FormControl id="delay" isRequired>
                <FormLabel>Tempo de espera</FormLabel>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Input
                    type="number"
                    name="time-value"
                    defaultValue={currentDelay.timeValue}
                  />

                  <Select
                    placeholder="Selecione a unidade de tempo"
                    id="time-type"
                    defaultValue={currentDelay.timeType}
                  >
                    <option value="s">Segundo</option>
                    <option value="m">Minutos</option>
                    <option value="h">Horas</option>
                  </Select>
                  <Button
                    variant={"outline"}
                    onClick={() => {
                      setOpenDelayModal(false);
                      setCurrentDelay({ timeValue: "", timeType: "" });
                    }}
                  >
                    Limpar
                  </Button>
                  <Button type="submit">Salvar</Button>
                </div>
              </FormControl>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default EditIntegration;
