import React, { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  useColorModeValue,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  SimpleGrid,
  Divider,
} from '@chakra-ui/react';
import {
  faHome,
  faPhone,
  faChartBar,
  faSignOutAlt,
  faClipboard,
  faSignal,
  faUserEdit,
  faQuestionCircle,
  faCog,
  faStar,
  faBars,
  faMoon,
  faSun
} from '@fortawesome/free-solid-svg-icons'; 
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, linearGradient, defs } from 'recharts';
import Navbar from '../components/Navbar';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO, isWithinInterval } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Dash.css';


// Dados fictícios por hora
const initialData = [
  { name: '2024-08-08 01:00', value: 80 },
  { name: '2024-08-08 02:00', value: 56 },
  { name: '2024-08-08 03:00', value: 121 },
  { name: '2024-08-08 04:00', value: 102 },
  { name: '2024-08-08 05:00', value: 92 },
  { name: '2024-08-08 06:00', value: 42 },
  { name: '2024-08-08 07:00', value: 59 },
  { name: '2024-08-08 08:00', value: 86 },
  { name: '2024-08-08 09:00', value: 77 },
  { name: '2024-08-08 10:00', value: 4769 },
  { name: '2024-08-08 11:00', value: 47 },
  { name: '2024-08-08 12:00', value: 140 },
  { name: '2024-08-08 13:00', value: 116 },
  { name: '2024-08-08 14:00', value: 128 },
  { name: '2024-08-08 15:00', value: 140 },
  { name: '2024-08-08 16:00', value: 1891 },
  { name: '2024-08-08 17:00', value: 227 },
  { name: '2024-08-08 18:00', value: 180 },
  { name: '2024-08-08 19:00', value: 176 },
  { name: '2024-08-08 20:00', value: 162 },
  { name: '2024-08-08 21:00', value: 128 },
  { name: '2024-08-08 22:00', value: 126 },
  { name: '2024-08-08 23:00', value: 180 },
  { name: '2024-08-08 24:00', value: 179 },
  { name: '2023-01-02 01:00', value: 65 },
  // Adicione mais dados conforme necessário
];

const StatBox = ({ title, number, helpText, statusColor }) => (
  <Box
    p={{ base: '4', md: '8' }}
    boxShadow="xl"
    borderRadius="lg"
    bg={useColorModeValue('white', 'gray.700')}
    textAlign="center"
    m="4"
  >
    <Stat>
      <StatLabel>{title}</StatLabel>
      <StatNumber fontSize="3xl">{number}</StatNumber>
      {helpText && <StatHelpText color={statusColor}>{helpText}</StatHelpText>}
    </Stat>
  </Box>
);

const Dashboard = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [data, setData] = useState(initialData);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      // Filtro de dados fictícios baseado nas datas selecionadas
      const filteredData = initialData.filter((item) => {
        const itemDate = parseISO(item.name); // Parse da string para um objeto Date
        return isWithinInterval(itemDate, { start, end });
      });

      setData(filteredData);
    }
  };

  return (
    <Flex
      className="bgfundo"
      style={{ background: 'url(bg.png) !important', backgroundSize: 'cover !important' }}
      minH="100vh"
      direction={{ base: 'column', md: 'row' }}
    >
      <Box width={{ base: '100%', md: '280px' }} p="4">
        <Navbar />
      </Box>
      <Flex className='oksx' direction="column" flex="1" p="4">
        {/* Parte superior com o título e o seletor de data */}
        <Flex justifyContent="space-between" alignItems="center" mb="6" direction={{ base: 'column', md: 'row' }}>
          <Text className='textsx x okx' as="h2" size="lg" textAlign={{ base: 'center', md: 'left' }} mb={{ base: '4', md: '0' }}>
            <Flex alignItems="center" justifyContent={{ base: 'center', md: 'flex-start' }}>
              <Box mr="2">
                <FontAwesomeIcon icon={faSignal} style={{ marginRight: '10px', color: '#6aab6d' }} />
              </Box>
              Dashboards
            </Flex>
          </Text>
          <Popover>
            <PopoverTrigger>
              <Button className='bgbt montserrat' size={{ base: 'sm', md: 'md' }}>Selecione o período</Button>
            </PopoverTrigger> 
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <DatePicker
                  selected={startDate}
                  onChange={handleDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  inline
                />
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>

        {/* Parte inferior com as estatísticas e o gráfico */}
        <Flex flex="1" direction={{ base: 'column', lg: 'row' }}>
          <Box width={{ base: '100%', lg: '280px' }} mb={{ base: '4', lg: '0' }}>
            <StatBox title="WhatsApp" number="10" />
            <StatBox title="Integrações" number="25" />
          </Box>
          <Box flex="1" ml={{ base: '0', lg: '4' }}>
            <Box bg={'white'} p={{ base: '4', md: '8' }} boxShadow="lg" borderRadius="lg">
              <Text fontSize="xl" fontWeight="bold" color="gray.600" mb="4">
                Disparos Processados
              </Text>
              <Box mb="4">
                <Flex justifyContent="space-between" alignItems="center">
                  <Text>Disparos processados (últimos 30 dias)</Text>
                  <Divider orientation="horizontal" borderColor="gray.300" flex="1" mx="2" />
                  <Text color="blue.500">4105</Text>
                </Flex>
              </Box>
              <Box mb="4">
                <Flex justifyContent="space-between" alignItems="center">
                  <Text>Número não usa WhatsApp</Text>
                  <Divider orientation="horizontal" borderColor="gray.300" flex="1" mx="2" />
                  <Text color="red.500">100</Text>
                </Flex>
              </Box>
              <Box mb="4">
                <Flex justifyContent="space-between" alignItems="center">
                  <Text>Disparos enviados</Text>
                  <Divider orientation="horizontal" borderColor="gray.300" flex="1" mx="2" />
                  <Text color="green.500">300</Text>
                </Flex>
              </Box>
              <Box mb="6">
                <Flex justifyContent="space-between" alignItems="center">
                  <Text>Aguardando envio</Text>
                  <Divider orientation="horizontal" borderColor="gray.300" flex="1" mx="2" />
                  <Text color="yellow.500">25</Text>
                </Flex>
              </Box>
              <Box height={{ base: '200px', md: '250px' }}>
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart data={data}>
                    <defs>
                      <linearGradient id="colorDisparos" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#63b3ed" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#63b3ed" stopOpacity={0} />
                      </linearGradient>
                      <linearGradient id="colorNaoUsaWhatsApp" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#fc8181" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#fc8181" stopOpacity={0} />
                      </linearGradient>
                      <linearGradient id="colorEnviado" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#68d391" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#68d391" stopOpacity={0} />
                      </linearGradient>
                      <linearGradient id="colorAguardandoEnvio" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#f6e05e" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#f6e05e" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Area type="monotone" dataKey="value" stroke="#63b3ed" fillOpacity={1} fill="url(#colorDisparos)" />
                    {/* Outras áreas que podem ser adicionadas conforme as suas necessidades */}
                    {/* <Area type="monotone" dataKey="value2" stroke="#fc8181" fillOpacity={1} fill="url(#colorNaoUsaWhatsApp)" /> */}
                    {/* <Area type="monotone" dataKey="value3" stroke="#68d391" fillOpacity={1} fill="url(#colorEnviado)" /> */}
                    {/* <Area type="monotone" dataKey="value4" stroke="#f6e05e" fillOpacity={1} fill="url(#colorAguardandoEnvio)" /> */}
                  </AreaChart>
                </ResponsiveContainer>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Dashboard;
