import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  Heading,
  Icon,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Text,
  useToast
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import io from 'socket.io-client';
import Navbar from '../components/Navbar';
import './Numbers.css';

const ManageNumbers = () => {
  const [numbers, setNumbers] = useState([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false); // Controle do popup de confirmação
const [numberToDelete, setNumberToDelete] = useState(null); // Armazena o ID do número que será deletado
const cancelRef = useRef(); // Referência para o botão de cancelar no AlertDialog

  const [newNumber, setNewNumber] = useState('');
  const [ddi, setDdi] = useState('55'); // Default to Brazil DDI
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isQrModalOpen, setIsQrModalOpen] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const toast = useToast();

  useEffect(() => {
    fetchNumbers();
    const socket = io('https://webhooks.simplificazap.com.br');

    const handleMessage = async (event) => {
      if (event.data === 'whatsapp-connected') {
        setIsQrModalOpen(false);
        toast({
          title: 'Número autenticado com sucesso!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        try {
          const token = localStorage.getItem('authToken');
          await axios.post(
            'https://webhooks.simplificazap.com.br/api/update-status',
            { number: selectedNumber, status: 'Active' },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          fetchNumbers();
        } catch (error) {
          console.error("Erro ao atualizar o status para 'Active': ", error);
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
      socket.disconnect();
    };
  }, [selectedNumber]);

  const fetchNumbers = async () => {
    const token = localStorage.getItem('authToken');
    const res = await axios.get('https://webhooks.simplificazap.com.br/api/numbers', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setNumbers(res.data);
  };

  const handleAddNumber = async () => {
    const token = localStorage.getItem('authToken');
    await axios.post(
      'https://webhooks.simplificazap.com.br/api/numbers',
      { number: `${ddi}${newNumber}` },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setNewNumber('');
    setDdi('55');
    fetchNumbers();
    setIsAddModalOpen(false);
  };

  // Função para abrir o alerta de confirmação antes de deletar
const openDeleteConfirmation = (id) => {
  setNumberToDelete(id); // Salva o ID do número a ser deletado
  setIsAlertOpen(true); // Abre o popup de confirmação
};

// Função para deletar o número após a confirmação
const handleDeleteNumber = async () => {
  const token = localStorage.getItem('authToken');
  await axios.delete(`https://webhooks.simplificazap.com.br/api/numbers/${numberToDelete}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  setIsAlertOpen(false); // Fecha o popup de confirmação
  fetchNumbers(); // Atualiza a lista de números
  toast({
    title: 'Número deletado com sucesso!',
    status: 'success',
    duration: 3000,
    isClosable: true,
  });
};

  const handleShowQrCode = (number) => {
    setSelectedNumber(number);
    setQrCode(`https://ec2-52-15-186-23.us-east-2.compute.amazonaws.com/scan/${number}`);
    setIsQrModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsQrModalOpen(false);
    setIsAddModalOpen(false);
  };

  const getStatusColor = (status) => {
    return status === 'Active' ? 'green.500' : 'red.500';
  };

  const getStatusText = (status) => {
    return status === 'Active' ? 'Ativado' : 'Desativado';
  };

  const getBackgroundStyle = () => {
    return theme === 'dark'
      ? { backgroundColor: '#1F2020', backgroundSize: "cover" }
      : { backgroundColor: '#F7F7F7' };
  };

  const getBackgroundStylex = () => {
    return theme === 'dark'
      ? { backgroundColor: '#F7F7F7' }
      : { backgroundColor: '#F7F7F7' };
  };


  const getCardBackgroundColor = () => {
    return theme === 'dark'
      ? { backgroundImage: "url('/img/bgshap.png')", backgroundSize: "cover" }
      : { backgroundColor: '#e8e8e8' } ;
  };

  const getCardBackgroundColosr = () => {
    return theme === 'dark'
    ? 'gray.700'
    : "url('/img/bgshap.png')";
  };


  const getTextColor = () => {
    return theme === 'dark' ? 'white' : 'gray.800';
  };

  const formatPhoneNumber = (phoneNumber) => {
    // Remover qualquer caractere não numérico
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  
    // Quebrar o número nas partes: código do país, DDD, parte do número
    const match = cleaned.match(/^(\d{2})(\d{2})(\d{5})(\d{4})$/);
  
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
  
    return phoneNumber; // Retornar o número original se não corresponder ao formato esperado
  };

  return (
    <Flex  direction="column" minHeight="100vh" style={getBackgroundStyle()} >
      <Navbar /> {/* Navbar mantida no topo */}
      <Box className='boxgerals' flex="1" p={8} ml={{ base: 0, md: 280 }} mt={{ base: 16, md: 20 }}>
        <Flex className='boxgeralssx headingdobox' justifyContent="space-between" alignItems="center" mb={8}>
          <Flex  alignItems="center">
            <Image src="/img/whats.svg" alt="WhatsApp" boxSize="40px" mr={2} />
            <Heading className='textsx'  as="h2" size="lg" color={getTextColor()}>
              WhatsApp
            </Heading>
          </Flex>
          <Button
  variant="ghost"
  onClick={() => setIsAddModalOpen(true)}
  display="flex"
  alignItems="center"
  justifyContent="center"
  _hover={{ background: 'none' }}
  _active={{ background: 'none' }}
>
  <Icon
    as={FaPlus}
    boxSize={34}
    color="green.500"
    border="2px solid"
    borderRadius="full"
    padding="4px"
  />
  <Text className='montserrat color' marginLeft="8px" color="green.500">
    Adicionar WhatsApp
  </Text>
</Button>
        </Flex>
        <SimpleGrid className='oks' columns={{ base: 1, md: 3 }} spacing={12}>
          {numbers.map((number) => (
            <GridItem key={number._id}>
              <Box
                 style={getCardBackgroundColor()}
                borderRadius="md"
                p={6}
                className='padding'
                 backgroundSize="cover"
                 backgroundPosition="center"
                boxShadow="md"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="200px"
              >
                <Flex className='centerx' mb={4}>
                  <Image
                    src="/img/flag/simplifica.png"
                    alt="Número"
                    borderRadius="full"
                    mr={4}
                  />
                  <Box className='centerxx'>
                    <Text className='mobnumber' fontWeight="bold" fontSize="lg" color={getTextColor()}>
                      WhatsApp 
                    </Text>
                    <Text className='mobnns' fontSize="md" color={getTextColor()}>
      {formatPhoneNumber(number.number)}
    </Text>
                  </Box>
                </Flex>
                <Flex alignItems="center" justifyContent="space-between" mt={2}>
                  <Flex alignItems="center">
                    <Box
                      w={3}
                      h={3}
                      bg={getStatusColor(number.status)}
                      borderRadius="full"
                      mr={2}
                    />
                     <Text
      className={`montserrat ${theme === 'dark' ? 'colors' : ''}`}
      color={getStatusColor(number.status)}
      fontWeight="bold"
    >
      {getStatusText(number.status)}
    </Text>
                  </Flex>
                  <Button className='botao'
  leftIcon={
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 90 91"
      width="24"  // Ajuste o tamanho conforme necessário
      height="24" // Ajuste o tamanho conforme necessário
    >
      <title>icons-svg</title>
      <defs>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp1">
          <path d="m23 5.22h44v81.75h-44z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp2">
          <path d="m0 1h10.59v17.72h-10.59z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp3">
          <path d="m-11.91-8.28h18c2.49 0 4.5 2.01 4.5 4.5v18c0 2.48-2.01 4.5-4.5 4.5h-18c-2.48 0-4.5-2.02-4.5-4.5v-18c0-2.49 2.02-4.5 4.5-4.5z" />
        </clipPath>
      </defs>
      <style>{".s0 { fill: #ffffff }"}</style>
      <path id="Layer" className="s0" d="m8.1 15.2c-5.2 5.2-8.1 12.1-8.1 19.5 0 7.3 2.9 14.3 8.1 19.5 0.4 0.4 0.9 0.5 1.4 0.5 0.5 0 1-0.1 1.4-0.5 0.8-0.8 0.8-2.1 0-2.9-4.4-4.4-6.9-10.3-6.9-16.6 0-6.3 2.5-12.2 6.9-16.6 0.8-0.8 0.8-2.1 0-2.9-0.7-0.8-2-0.8-2.8 0z" />
      <path id="Layer" className="s0" d="m18.3 20.7c-0.7-0.8-2-0.8-2.8 0-3.7 3.7-5.8 8.7-5.8 14 0 5.3 2.1 10.3 5.8 14 0.4 0.4 0.9 0.6 1.4 0.6 0.5 0 1-0.2 1.4-0.6 0.8-0.8 0.8-2.1 0-2.9-3-2.9-4.6-6.9-4.6-11.1 0-4.2 1.6-8.2 4.6-11.2 0.8-0.7 0.8-2 0-2.8z" />
      <path id="Layer" className="s0" d="m79.1 15.2c-0.8 0.8-0.8 2.1 0 2.9 4.4 4.4 6.9 10.3 6.9 16.6 0 6.3-2.5 12.2-6.9 16.6-0.8 0.8-0.8 2.1 0 2.9 0.4 0.3 0.9 0.5 1.4 0.5 0.5 0 1-0.2 1.4-0.5 5.2-5.2 8.1-12.2 8.1-19.5 0-7.4-2.9-14.3-8.1-19.5-0.8-0.8-2.1-0.8-2.8 0z" />
      <path id="Layer" className="s0" d="m71.7 48.7c0.4 0.4 0.9 0.6 1.4 0.6 0.5 0 1-0.2 1.4-0.6 3.7-3.8 5.8-8.7 5.8-14 0-5.3-2.1-10.3-5.8-14-0.8-0.8-2.1-0.8-2.8 0-0.8 0.8-0.8 2.1 0 2.8 3 3 4.6 7 4.6 11.2 0 4.2-1.6 8.2-4.6 11.1-0.8 0.8-0.8 2.1 0 2.9z" />
      <g id="Clip-Path" clipPath="url(#cp1)">
        <g id="Layer">
          <path
            id="Layer"
            fillRule="evenodd"
            className="s0"
            d="m66.4 12.5v67c0 4-3.2 7.2-7.2 7.2h-28.4c-4 0-7.2-3.2-7.2-7.2v-67c0-4 3.2-7.2 7.2-7.2h28.4c4 0 7.2 3.2 7.2 7.2zm-38.8 57.8h34.8v-53.3h-34.8zm0-57.7v0.5h34.8v-0.5c0-1.8-1.4-3.3-3.2-3.3h-28.4c-1.8 0-3.2 1.5-3.2 3.3zm34.8 67v-5.3h-34.8v5.3c0 1.8 1.4 3.2 3.2 3.2h28.4c1.8 0 3.2-1.4 3.2-3.2z"
          />
        </g>
      </g>
      <path id="Layer" className="s0" d="m47.3 78.2q0 0.5-0.2 0.9-0.2 0.4-0.5 0.7-0.3 0.4-0.7 0.5-0.5 0.2-0.9 0.2-0.4 0-0.9-0.2-0.4-0.1-0.7-0.5-0.3-0.3-0.5-0.7-0.2-0.4-0.2-0.9 0-0.4 0.2-0.8 0.2-0.4 0.5-0.8 0.3-0.3 0.7-0.4 0.5-0.2 0.9-0.2 0.4 0 0.9 0.2 0.4 0.1 0.7 0.4 0.3 0.4 0.5 0.8 0.2 0.4 0.2 0.8z" />
      <g id="Clip-Path" clipPath="url(#cp2)">
        <g id="Layer">
          <g id="Clip-Path" clipPath="url(#cp3)">
            <g id="Layer"></g>
          </g>
        </g>
      </g>
    </svg>
  }
  colorScheme={number.status === 'Active' ? 'red' : 'green'}
  bg={number.status === 'Active' ? '#FF5C5C' : '#4CAF50'}
  color="white"
  style={{ color: 'white !important' }}

  _hover={{
    bg: number.status === 'Active' ? '#FF3D3D' : '#43A047',
  }}
  onClick={
    number.status === 'Active'
      ? () => handleDeleteNumber(number._id)
      : () => handleShowQrCode(number.number)
  }
>
  {number.status === 'Active' ? 'Desativar' : 'Ativar'}
</Button>


                </Flex>
              </Box>
            </GridItem>
          ))}
        </SimpleGrid>
      </Box>
      <AlertDialog
  isOpen={isAlertOpen}
  leastDestructiveRef={cancelRef}
  onClose={() => setIsAlertOpen(false)}
>
  <AlertDialogOverlay>
    <AlertDialogContent>
      <AlertDialogHeader fontSize="lg" fontWeight="bold">
        Deletar Número
      </AlertDialogHeader>

      <AlertDialogBody>
        Tem certeza que deseja deletar este número? Esta ação não pode ser desfeita.
      </AlertDialogBody>

      <AlertDialogFooter>
        <Button ref={cancelRef} onClick={() => setIsAlertOpen(false)}>
          Cancelar
        </Button>
        <Button colorScheme="red" onClick={handleDeleteNumber} ml={3}>
          Deletar
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialogOverlay>
</AlertDialog>
      {/* Modal de Adicionar Número */}
      <Modal  isOpen={isAddModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent style={getBackgroundStylex()}>
          <ModalHeader>Adicionar Número</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>DDI</FormLabel>
              <Select className='border' value={ddi} onChange={(e) => setDdi(e.target.value)}>
                <option value="55">Brasil (+55)</option>
              </Select>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Número de Telefone</FormLabel>
              <Input
                placeholder="Digite o número de telefone"
                className='border'
                value={newNumber}
                onChange={(e) => setNewNumber(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button className='noatuars' colorScheme="green" onClick={handleAddNumber}>
              Adicionar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal de QR Code */}
      <Modal isOpen={isQrModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Escanear QR Code</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <iframe
                src={qrCode}
                width="100%"
                height="400px"
                frameBorder="0"
                style={{ pointerEvents: 'none' }}
              />
            </Center>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" onClick={handleCloseModal}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default ManageNumbers;
